<template>
    <b-card title="Example Strings">
        <b-row>
            <b-col sm="2">
                <label for="firstname">Firstname</label>
            </b-col>
            <b-col sm="10">
                <b-input type="text" id="firstname" placeholder="Firstname" v-model="firstname"></b-input>
            </b-col>
            <b-col sm="2">
                <label for="lastname">Lastname</label>
            </b-col>
            <b-col sm="10">
                <b-input type="text" id="lastname" placeholder="lastname" v-model="lastname"></b-input>
            </b-col>
        </b-row>
        <div>Welcome {{fullname}}!</div>
    </b-card>
</template>

<script>
export default {
    name: "StringExample",
    data () {
        return {
            firstname: 'John',
            lastname: 'Doe',
            fullname: '',
        }
    },
    watch: {
        firstname: {
            handler: function (newVal, oldVal) {
              console.log(oldVal);
                this.fullname = `${newVal.toUpperCase()} ${this.lastname.toUpperCase()}`;
            }
        },
        lastname: {
            handler: function () {
                this.fullname = `${this.firstname.toUpperCase()} ${this.lastname.toUpperCase()}`;
            }
        },
    }
}
</script>

<style scoped>

</style>
