<template>
    <div>

        <heading titletype="titleh2"><template v-slot:text>Demo page</template></heading>

        <section>
            <heading titletype="titleh3"><template v-slot:text>Array example</template></heading>
            <array-example class="my-2"></array-example>
        </section>

        <section>
            <heading titletype="titleh3"><template v-slot:text>String example</template></heading>
            <string-example class="my-2"></string-example>
        </section>

        <section>
           <heading titletype="titleh3"><template v-slot:text>Slot example</template></heading>
           <slot-component>
            <template v-slot:message>
                <h1>Slots are easy!</h1>
            </template>
        </slot-component>
    </section>

</div>
</template>

<script>
    import ArrayExample from "@/examples/ArrayExample";
    import StringExample from "@/examples/StringExample";
    import SlotComponent from "@/examples/SlotComponent";
    import Headings from "@/components/Headings.vue";
    export default {
        name: "DemoPage",
        
        components: {
            SlotComponent, 
            StringExample, 
            ArrayExample,
            'heading': Headings
        },
    }
</script>

<style scoped>

</style>
