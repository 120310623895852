<template>
    <b-card title="Example Arrays">
        <div class="mx-2 my-2">Total number of tasks: {{tasklength}}</div>
        <div>
            <b-row>
                <b-col>
                    <b-form-input type="text" v-model="inputText"/>
                </b-col>
                <b-col>
                    <b-button variant="success" v-on:click="addElement">Add</b-button>
                </b-col>
            </b-row>
        </div>
        <ol class="mt-1" v-if="tasksNumber > 0">
            <!--            <li v-for="(el,index) in tasks" v-bind:id="'uniqueId-' + index">{{el.title}}</li>-->
            <task-component v-for="(task, index) in tasks"
            v-model:task="tasks[index]"
            :key="index"
            v-model:users="userNames"
            :status="task.done"
            :eventKey="`tasks${index}`"
            v-on:[`tasks${index}`]="dynamicEvent"></task-component>
        </ol>
        <div class="mx-2">Total number of tasks: {{tasklength}}</div>
    </b-card>
</template>

<script>
    import TaskComponent from "@/examples/TaskComponent";
    export default {
        name: "ArrayExample",
        components: {TaskComponent},
        data() {
            return {
                tasks: [{title: 'Finish work', done: true, user: 'Ari Warda'}, {title: 'Take out garbadge', done: false, user: ''}, {title: 'Make Vue.js course', done: false, user: ''}],
                users: [{id: 1, lastname: 'Ari', firstname: 'Warda'}, {id: 2, lastname: 'Peeters', firstname: 'Lucas'}, {id: 3, lastname: 'Aquino', firstname: 'Mario'}],
                inputText: '',
                tasklength: 0,
            }
        },
        methods: {
            addElement() {
                this.tasks.push({
                    title: this.inputText,
                    done: false,
                    user: '',

                });
            },
            dynamicEvent(param) {
                console.log('dynamic event');
                console.log(param);
            },
        },
        computed: {
            tasksNumber: function () {
                return this.tasks.length;
            },
            userNames: function() {
                return this.users.map((el) => el.firstname + ' ' + el.lastname);
            },
            calculateLength() {
                return this.tasks.length;
            }
        }
    }
</script>

<style scoped>

</style>
