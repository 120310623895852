<template>
    <div :style="style">
        <slot name="message"></slot>
    </div>

</template>

<script>
export default {
    name: "SlotComponent",
    data() {
        return {
            style: {
                fontSize: 14,
                color: 'blue',
            }
        }
    },
}
</script>

<style scoped>

</style>
